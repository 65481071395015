/* Sidebar Styling */
.sidebar {
  width: 180px; /* Narrower width */
  padding: 15px;
  background: linear-gradient(135deg, #0b3d3a, #102e29); /* Refined dark green gradient */
  color: #f1f1f1; /* Softer light text color */
  position: fixed;
  top: 80px; /* Aligned with the navbar */
  left: 0;
  bottom: 200px; /* Aligned with the footer */
  overflow-y: auto;
  z-index: 1; /* Ensures it's below game panels */
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px 0 0 15px; /* Subtle rounded corners */
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  transition: all 0.3s ease-in-out;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Sidebar Title */
.sidebar h2 {
  font-size: 1.4em;
  color: #fffcf2;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 5px;
}

/* List Styling */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* List Items for Player Actions */
.sidebar ul li {
  font-size: 0.95em;
  color: #e8e8e8;
  padding: 8px 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Player Action Details */
.sidebar ul li span {
  color: #ffd700; /* Gold for multipliers or bet highlights */
}

/* Hover Effects for List Items */
.sidebar ul li:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.03);
  color: #f9e56d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Status Effects */
.sidebar ul li.cashed-out {
  background: rgba(0, 128, 0, 0.2); /* Soft green */
  animation: flash-green 1s infinite alternate;
}

.sidebar ul li.crashed {
  background: rgba(255, 0, 0, 0.2); /* Soft red */
  animation: flash-red 1s infinite alternate;
}

@keyframes flash-green {
  0% {
    background-color: rgba(0, 128, 0, 0.3);
  }
  100% {
    background-color: rgba(0, 128, 0, 0.5);
  }
}

@keyframes flash-red {
  0% {
    background-color: rgba(255, 0, 0, 0.3);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.5);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 150px; /* Further reduced width for small screens */
    padding: 10px;
  }

  .sidebar h2 {
    font-size: 1.2em;
  }

  .sidebar ul li {
    font-size: 0.85em;
    padding: 6px 8px;
  }
}
