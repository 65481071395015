/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure it sits behind all other elements */
}

.app-container {
  position: relative;
  z-index: 1;
}


.game-status {
  position: absolute; /* Position the game status relative to its parent */
  top: 10%; /* Adjust this to move the text higher or lower */
  width: 100%; /* Take up full width of the container */
  text-align: center;
}


.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* Blue-green gradient */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.auth-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.auth-form input[type="email"],
.auth-form input[type="text"] {
  padding: 12px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.auth-form input[type="email"]:focus,
.auth-form input[type="text"]:focus {
  border-color: #00b894; /* Green focus color */
  box-shadow: 0 0 8px rgba(0, 184, 148, 0.5); /* Green glow effect */
  outline: none;
}

.auth-form button {
  padding: 12px;
  background: #00b894; /* Green button */
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.auth-form button:hover {
  background: #00cec9; /* Lighter green on hover */
}

.auth-container p {
  color: red;
  font-size: 1rem;
  margin-top: 15px;
  text-align: center;
}



.region {
  flex: 1;
  height: 100%;
  background-color: lightblue;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.region:hover {
  background-color: lightgreen;
}


.logout-button {
  position: absolute;
  top: 20px; /* Adjust this to move the button closer or further from the top */
  left: 20px; /* Keeps the button near the left edge */
  font-size: 24px; /* Adjust font size as needed */
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Transparent background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensure the button is above other content */
}

.logout-button:hover {
  background-color: rgba(0, 0, 0, 0.9); /* Darken the background on hover */
}



.header {
  text-align: center;
  padding: 10px;
  background-color: #005cbf;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}



/* Player Panel and Main Content */
.game-section {
  padding-bottom: 150px; /* Ensures space for player panel at the bottom */
  position: relative;
  z-index: 1; /* Ensure game content stays on top */
}

/* Adding responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 200px; /* Slightly smaller width on mobile */
  }

  .sidebar h3 {
    font-size: 1.2em; /* Adjusted font size for smaller screens */
  }

  .sidebar ul li {
    font-size: 1em; /* Slightly smaller font size on mobile */
    padding: 10px; /* Adjust padding for smaller screen */
  }
}
/* Player panels container - align and position adjustments */
.player-panels {
  display: flex;
  justify-content: flex-start; /* Align items from the start */
  align-items: center; /* Vertically align items */
  flex-wrap: nowrap; /* Prevent wrapping to new rows */
  overflow-x: auto; /* Enable horizontal scrolling if there are too many panels */
  gap: 0; /* No space between player panels */
  padding: 10px;
  border-top: 2px solid #0288d1;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: fixed; /* Fix the panels to the bottom of the screen */
  bottom: 0;
  left: 0; /* Start at the left edge of the viewport */
  right: 0; /* End at the right edge of the viewport */
  height: auto; /* Ensure it fits within the container */
  box-sizing: border-box;
  background-color: transparent; /* Makes the background transparent */
  z-index: 1; /* Ensure player panels sit above the sidebar */
}

/* Player cursor style for each panel */
.player-cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none; /* Prevent the virtual cursor from blocking clicks */
  z-index: 9999;
}

/* Player name adjustments - top-right corner */
.player-name {
  font-size: 18px; /* Adjusted size for better layout */
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Shadow effect */
  user-select: none; /* Disable text selection */
}

/* Level dropdown adjustment */
.level-dropdown {
  position: absolute;
  bottom: 40px; /* Adjust position below the player name */
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #0288d1;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
}

/* Virtual cursor positioning */
.player-panel .virtual-cursor {
  position: absolute;
  bottom: 10px; /* Adjust to the bottom */
  width: 20px;
  height: 20px;
  background-color: #FFD700;
  border-radius: 50%;
  pointer-events: none; /* Ensure cursor doesn't interfere with other elements */
}

/* Styles for text like large text and messages */
.large-text {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  display: inline-block;
}

/* Cashout message styling */
.cashout-message h3 {
  color: #ff4500;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.cashout-message p {
  font-size: 1.2em;
  color: #005cbf;
}

/* Disable text selection for the entire panel */
.player-panel {
  user-select: none; /* Prevent text selection */
}

/* Large screen styles */
@media (min-width: 769px) {
  .sidebar {
    display: block;
  }


  .player-panels {
    flex-direction: row;
    padding: 10px;
  }

  .player-panel {
    flex: 1 1 200px;
    max-width: 200px;
    font-size: 1em;
  }

  .header {
    font-size: 1.5em;
  }

}.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dim background */
  z-index: 1000;
}

.loader {
  --s: 50px; /* Increase the size of the loader */
  --g: 10px; /* Increase the gap between segments */
  width: calc(3 * (1.353 * var(--s) + var(--g)));
  display: grid;
  justify-items: end;
  aspect-ratio: 3;
  overflow: hidden;
  --_m: linear-gradient(90deg, #0000, #000 15px calc(100% - 15px), #0000);
  -webkit-mask: var(--_m);
  mask: var(--_m);
}

.loader:before {
  content: "";
  width: 200%;
  background: linear-gradient(90deg, #ff1818 50%, #0000 0),
    conic-gradient(
      from -90deg at var(--s) calc(0.353 * var(--s)),
      #fff 135deg,
      #666 0 270deg,
      #aaa 0
    );
  background-blend-mode: multiply;
  --_m: linear-gradient(
      to bottom right,
      #0000 calc(0.25 * var(--s)),
      #000 0 calc(100% - calc(0.25 * var(--s)) - 1.414 * var(--g)),
      #0000 0
    ),
    conic-gradient(from -90deg at right var(--g) bottom var(--g), #000 90deg, #0000 0);
  -webkit-mask: var(--_m);
  mask: var(--_m);
  background-size: calc(100% / 3) 100%, calc(100% / 6) 100%;
  -webkit-mask-size: calc(100% / 6) 100%;
  mask-size: calc(100% / 6) 100%;
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
  animation: l10 1s infinite linear;
}

@keyframes l10 {
  to {
    transform: translate(calc(100% / 3));
  }
}

.game-status-text {
  font-size: 48px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  color: #FFD700; /* Golden color */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.game-status-multiplier {
  background: linear-gradient(45deg, #ffcc00, #ff9900);
  -webkit-background-clip: text;
  color: transparent;
  animation: glow 1.5s ease-in-out infinite alternate;
}

.countdown-display {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.countdown-text {
  font-size: 48px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: #4CAF50; /* Green color */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); /* Text shadow for depth */
  animation: fadeIn 1s ease-in-out;
}

.countdown-loader {
  font-weight: bold;
  font-family: 'Courier New', monospace;
  display: inline-grid;
  font-size: 30px;
  margin-top: 10px;
  color: #FFD700; /* Golden color */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.countdown-loader:before,
.countdown-loader:after {
  content: "Loading...";
  grid-area: 1/1;
  -webkit-mask-size: 2ch 100%, 100% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: l37 1s infinite;
}

.countdown-loader:before {
  -webkit-mask-image:
    linear-gradient(#000 0 0),
    linear-gradient(#000 0 0);
}

.countdown-loader:after {
  -webkit-mask-image: linear-gradient(#000 0 0);
  transform: scaleY(0.5);
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6);
  }
  100% {
    text-shadow: 0 0 30px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 0.8);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes l37 {
  0%    {-webkit-mask-position: 1ch 0, 0 0;}
  12.5% {-webkit-mask-position: 100% 0, 0 0;}
  25%   {-webkit-mask-position: 4ch 0, 0 0;}
  37.5% {-webkit-mask-position: 8ch 0, 0 0;}
  50%   {-webkit-mask-position: 2ch 0, 0 0;}
  62.5% {-webkit-mask-position: 100% 0, 0 0;}
  75%   {-webkit-mask-position: 0ch 0, 0 0;}
  87.5% {-webkit-mask-position: 6ch 0, 0 0;}
  100%  {-webkit-mask-position: 3ch 0, 0 0;}
}

