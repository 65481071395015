/* Main Navbar Container */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #1a1a1a;
  color: white;
  padding: 5px 10px; /* Reduced padding */
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Segoe UI', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Reduced gap */
}

/* Logo Section */
.navbar-logo {
  font-size: 16px; /* Smaller font size */
  font-weight: bold;
  color: #FFD700; /* Gold */
  text-align: center;
  margin-bottom: 2px; /* Reduced margin */
}

/* Game History Section */
.navbar-history {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px; /* Smaller gap */
  padding: 2px; /* Reduced padding */
  background: #222;
  border-radius: 4px; /* Smaller radius */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4);
}

.history-item {
  font-size: 10px; /* Smaller font */
  padding: 2px 4px; /* Reduced padding */
  border-radius: 2px; /* Smaller radius */
  color: #fff;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.history-green {
  background-color: #28a745; /* Green */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 2px;
}

.history-red {
  background-color: #dc3545; /* Red */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 2px;
}

.history-blue {
  background-color: #007bff; /* Blue */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 2px;
}

.history-gold {
  background-color: #ffd700; /* Gold */
  color: black;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 2px;
}


.history-item:hover {
  transform: scale(1.05); /* Slight hover effect */
  background-color: #333;
}

/* Buttons Section */
.navbar-buttons {
  display: flex;
  gap: 8px; /* Smaller gap */
  justify-content: center;
  flex-wrap: wrap;
}

/* Individual Button Styles */
.navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px; /* Reduced padding for smaller height */
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px; /* Smaller radius for compactness */
  min-width: 70px; /* Smaller width */
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.navbar-item:hover {
  background-color: #444;
  transform: scale(1.05); /* Slight hover effect */
}

.navbar-item p {
  margin: 0; /* Removed margin */
  font-size: 9px; /* Smaller font for text */
  color: #bbb;
}

/* Progress Bar */
.progress-bar {
  width: 100%;
  height: 3px; /* Reduced height for progress bar */
  background-color: #444;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.progress-bar .progress {
  height: 100%;
  transition: width 0.2s ease;
}

.jackpot .progress {
  background-color: #FFD700; /* Gold */
}

.bonus .progress {
  background-color: #28a745; /* Green */
}

.gift .progress {
  background-color: #1E90FF; /* Blue */
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 5px;
  }

  .navbar-logo {
    font-size: 14px; /* Adjusted font size */
  }

  .history-item {
    font-size: 8px; /* Smaller font */
    padding: 2px 3px; /* Adjusted padding */
  }

  .navbar-item {
    min-width: 70px; /* Smaller width */
    padding: 4px; /* Reduced padding */
  }

  .navbar-buttons {
    gap: 5px; /* Adjusted gap */
  }
}
