@keyframes boomAnimation {
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1.5) rotate(20deg);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(360deg);
  }
}

.crashed-text {
  font-size: 48px;
  font-weight: bold;
  color: red;
  text-align: center;
  animation: boomAnimation 1s forwards; /* 1s duration for the animation */
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.6), 0 0 20px rgba(255, 0, 0, 0.8), 0 0 30px rgba(255, 0, 0, 1);
}

.canvas {
  position: relative;
  display: inline-block;
}

canvas {
    border: 1px solid #ccc;
    background-color: #f5f5f5; /* Light gray background */
    display: block;
    margin: 2px auto;
    border-radius: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

body {
    font-family: Arial, sans-serif;
    background-color: #eef2f3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}